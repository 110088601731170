import React, { Component } from "react";
import { applyVueInReact } from "vuereact-combined";
import LoadingSpinner from "@/components/LoadingSpinner";
import Payment from "../../components/payment/Payment";
import axios from "@/axios";

class PrabhuTvSetUp extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);

  constructor() {
    super();
    this.state = {
      message: "",
      reports: "",
      casId: "",
      amount: 0,
      loading: true,
    };
  }
  componentDidMount() {
    this.setState({ loading: false });
  }

  handleCasIdChange = (event) => {
    this.setState({ casId: event.target.value.trim() });
  };
  handleAmountChange = (event) => {
    this.setState({ amount: event.target.value });
  };

  validationBeforePayment = () => {
    if (Number(Number.isInteger(Number(this.state.amount)))) {
      if (!(this.state.amount >= 350 && this.state.amount <= 30000)) {
        this.setState({ message: "Amount must be between 350 and 30000." });
        return false;
      }
    } else {
      this.setState({ message: "Amount must not contain decimal." });
      return false;
    }
    return true;
  };

  checkPrabhuTvSetUp = async (event) => {
    event.preventDefault();

    if (this.state.casId.length === 0) {
      this.setState({ message: "Please enter the CasId first." });
      return;
    }

    if (this.state.casId.length !== 11 && this.state.casId.length !== 16) {
      this.setState({
        message:
          "ChipId/VSC Id Should be 11 Digit and Smart Card Id Should be 16 Digit",
      });
      return;
    }

    this.setState({ loading: true });
    const data = await axios.post(
      "api/v2/services/prabhutv/checkPrabhuTvAccount",
      {
        CasId: this.state.casId,
      }
    );
    this.setState({ loading: false });
    if (data.data && data.data.response && data.data.response.Code === "000") {
      this.setState({ reports: data.data.response });
    } else {
      this.setState({ data: data.data.response.Message });
    }
  };

  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            <h4>Prabhu Tv SetUp TopUp</h4>
            <div className="form-group">
              {this.state.message && (
                <div className="show-message">{this.state.message}</div>
              )}
            </div>
            <div className="row">
              <div className="col-md-7">
                {this.state.reports ? (
                  //If reports
                  <div>
                    <div className="mt-4">
                      <div className="font-weight-bold">CustomerId</div>
                      <div>{this.state.reports.CustomerId}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Customer Name</div>
                      <div>{this.state.reports.CustomerName}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">CasId</div>
                      <div>{this.state.reports.CasId}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Balance</div>
                      <div>{this.state.reports.Balance}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Current Plan</div>
                      <div>
                        <ul>
                          {this.state.reports.CurrentPlans.map(
                            (plan, index) => {
                              return (
                                <li key={`${plan.planName}-${index}`}>
                                  Plan Name: {plan.PlanName}
                                  Expiry Date:{plan.PlanExpiryDate}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="form-group">
                        <label>
                          {" "}
                          Amount <i className="fa fa-asterisk"></i>
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Type Amount and press enter."
                          value={this.state.amount}
                          onChange={this.handleAmountChange}
                        />
                      </div>
                    </div>
                    {/* Payment Division */}
                    <div className="fade-animation">
                      <Payment
                        paymentUri="api/v4/prabhu_tv_setup_topup/payment"
                        paymentPayload={{
                          CasId: this.state.casId,
                          Amount: this.state.amount,
                        }}
                        setMessage={(message) => {
                          this.setState({ message });
                        }}
                        validationBeforePayment={this.validationBeforePayment}
                        paymentPreviewDetail={{
                          "Customer Name": this.state.reports.CustomerName,
                          "Total Amount to Pay": "Rs. " + this.state.amount,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  //If no report
                  <form>
                    <div className="form-group">
                      <label>
                        CasId/Smart CardId <i className="fa fa-asterisk"></i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type CasId/Smart CardId and press enter."
                        value={this.state.casId}
                        onChange={this.handleCasIdChange}
                      />
                      <button
                        className="btn btn-primary mt-3"
                        onClick={this.checkPrabhuTvSetUp}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default PrabhuTvSetUp;
